<template>
  <div class="events-holder">
    <transition
      enter-active-class="animate__animated animate__faster animate__fadeInDown"
      leave-active-class="animate__animated animate__faster animate__fadeOut"
    >
      <div v-if="speedupEvent" class="top-event speedup">
        <img src="@/assets/images/speedup-clock.svg" />
        <div class="counter">{{ countDown(counters.speedup, durations.speedup) }}</div>
      </div>
    </transition>
    <transition
      enter-active-class="animate__animated animate__faster animate__fadeInDown"
      leave-active-class="animate__animated animate__faster animate__fadeOut"
    >
      <div v-if="freezeEvent" class="top-event freeze">
        <img src="@/assets/images/freeze-icon.svg" />
        <div class="counter">{{ countDown(counters.freeze, durations.freeze) }}</div>
      </div>
    </transition>
    <transition
      enter-active-class="animate__animated animate__faster animate__fadeInUp"
      leave-active-class="animate__animated animate__faster animate__fadeOut"
    >
      <div v-if="addPointsEvent.show" class="text-feedback addPoints">
        +{{ addPointsEvent.points }}<br />Super!
      </div>
    </transition>
    <transition
      enter-active-class="animate__animated animate__faster animate__fadeInUp"
      leave-active-class="animate__animated animate__faster animate__fadeOut"
    >
      <div v-if="speedupTextEvent" class="text-feedback speedup">Wow!</div>
    </transition>
    <transition
      enter-active-class="animate__animated animate__faster animate__fadeInUp"
      leave-active-class="animate__animated animate__faster animate__fadeOut"
    >
      <div v-if="freezeTextEvent" class="text-feedback freeze">Brrr.</div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      speedupEvent: false,
      speedupTextEvent: false,
      freezeEvent: false,
      freezeTextEvent: false,
      addPointsEvent: { show: false, points: 100 },
      durations: {
        speedup: 5,
        freeze: 3
      },
      counters: {
        speedup: 0,
        freeze: 0
      },
      timers: {
        speedup: Object,
        freeze: Object
      }
    }
  },
  mounted() {},
  methods: {
    startFreezeEvent() {
      this.hideAllTextEvents()

      if (this.$store.gameData.isSpeedup) {
        this.clearSpeedupEvent()
      }
      this.freezeEvent = true
      this.freezeTextEvent = true
      setTimeout(() => {
        this.freezeTextEvent = false
      }, 500)
      this.timers.freeze = window.rInterval(() => {
        this.counters.freeze += 1
        if (this.counters.freeze >= this.durations.freeze) {
          this.timers.freeze.clear()
          this.counters.freeze = 0
          this.freezeEvent = false
        }
      }, 1000)
    },
    startSpeedupEvent() {
      this.hideAllTextEvents()
      this.speedupEvent = true
      this.speedupTextEvent = true
      setTimeout(() => {
        this.speedupTextEvent = false
      }, 500)
      this.timers.speedup = window.rInterval(() => {
        this.counters.speedup += 1
        if (this.counters.speedup >= this.durations.speedup) {
          this.clearSpeedupEvent()
        }
      }, 1000)
    },
    clearSpeedupEvent() {
      this.timers.speedup.clear()
      this.counters.speedup = 0
      this.speedupEvent = false
    },
    countDown(counter, duration) {
      return duration - counter + 's'
    },
    hideAllTextEvents() {
      this.addPointsEvent.show = false
      this.speedupTextEvent = false
      this.freezeTextEvent = false
    }
  },
  computed: {
    gameEvent() {
      return this.$store.gameData.eventBus.slice(-1)[0]
    }
  },
  watch: {
    gameEvent(event) {
      switch (event.type) {
        case 'addPoints':
          this.hideAllTextEvents()
          this.addPointsEvent.show = true
          this.addPointsEvent.points = event.points
          setTimeout(() => {
            this.addPointsEvent.show = false
          }, 500)
          break

        case 'freeze':
          this.startFreezeEvent()
          break

        case 'speedup':
          this.startSpeedupEvent()
          break
      }
    }
  }
}
</script>

<style scoped lang="scss">
.events-holder {
  position: absolute;
  display: flex;
  justify-content: center;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;

  .top-event {
    position: absolute;
    top: 130px;
    background: #fff;
    font-size: 25px;
    color: #101820;
    font-weight: 800;
    display: inline-flex;
    padding: 5px 10px;
    border-radius: 20px;

    &.freeze {
      background: #408ed9;
      color: #fff;
    }

    img {
      margin-right: 5px;
    }

    .counter {
      transform: translateY(3px);
    }
  }

  .text-feedback {
    position: absolute;
    line-height: 1;
    text-align: center;
    bottom: 300px;
    font-size: 51px;
    font-weight: 800;

    &.addPoints {
      font-size: 44px;
    }
  }
}
</style>
