<script setup>
import PhaserContainer from '@/components/PhaserContainer.vue'
</script>

<template>
  <Suspense>
    <PhaserContainer v-if="!gameFinished" />
  </Suspense>
  <div v-if="!gameLoaded" class="placeholder">
    <div class="loading loading-lg"></div>
  </div>
  <transition
    enter-active-class="animate__animated animate__fadeInUp"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <div class="ending" v-if="gameFinished">
      <h1>De tijd<br />is op!</h1>
    </div>
  </transition>
</template>

<script>
export default {
  mounted() {
    this.$store.gameLoaded = false
  },
  computed: {
    gameLoaded() {
      return this.$store.gameLoaded
    },
    gameFinished() {
      return this.$store.gameFinished
    }
  },
  watch: {
    gameFinished(bool) {
      if (bool) {
        setTimeout(() => {
          this.$router.push({ name: 'score' })
        }, 4000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.placeholder {
  background: #000;
  display: block;
  min-height: 1000px;
  font-size: 2rem;
  font-family: 'Courier New', Courier, monospace;
}

.ending {
  h1 {
    font-size: 54px;
    text-align: center;
    line-height: 1;
    margin-top: 40%;
  }
}
</style>
