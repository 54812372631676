<template>
  <TopBar />
  <div class="wrapper">
    <div class="landing-wrapper">
      <img class="key-visual" src="@/assets/images/key-visual.png" />
      <h1 class="text-center">Klaar voor de start?</h1>
      <p class="text-center">
        Je kunt weer meespelen voor de nieuwe prijzen<template v-if="previousPlayedSessions">
          , én je ontvangt hierbij een extra lot</template
        >!
      </p>

      <div v-if="previousPlayedSessions" class="text-center">
        <div class="coupons">
          <div class="coupons-wrapper">
            <img src="@/assets/images/coupon.svg" />
            <span class="coupon">+{{ previousPlayedSessions }}</span>
          </div>
          <div class="coupons-content">
            Omdat je <span class="highlight">{{ previousPlayedSessions }}x eerder</span> speelde
          </div>
        </div>
      </div>

      <div class="button-wrapper">
        <button @click="startGame()" class="btn btn-primary">Start het spel</button>
        <button
          v-if="$store.player.round !== 5"
          @click="$router.push({ name: 'instructions' })"
          class="btn btn-outline"
        >
          Bekijk speluitleg
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import TopBar from '@/components/TopBar.vue'
</script>

<script>
export default {
  data() {
    return {}
  },
  created() {},
  mounted() {
    const gameSessions = Object.values(this.$store.player.gameSessions)
    const lastGame = gameSessions[gameSessions.length - 1]
    this.$store.dataLayerPush({
      event: 'open_game',
      game_counter: gameSessions.length,
      last_game_date: lastGame ? lastGame.date : false,
      status: 'available'
    })
  },
  computed: {
    previousPlayedSessions() {
      return this.$store.player.previousPlayedSessions
    }
  },
  methods: {
    startGame() {
      if (this.$store.player.round === 5) {
        this.$router.push({ name: 'instructions' })
      } else {
        this.$router.push({ name: 'game' })
      }
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.landing-wrapper {
  margin-top: 40px;
  padding: 0 40px;

  .key-visual {
    width: 100%;
  }

  .terms-link-wrapper {
    margin: 40px 0;
    text-align: center;
  }

  .button-wrapper {
    .btn {
      margin-bottom: 20px;
    }
  }

  .coupons {
    display: inline-flex;
    align-items: center;
    padding: 5px 8px;
    background: #2f363d;
    color: rgba(#fff, 0.7);
    border: solid 1px #515860;
    border-radius: 50px;
    font-size: 16px;
    line-height: 2;
    margin-bottom: 20px;

    .highlight {
      color: #fff;
      font-weight: 800;
    }

    .coupons-wrapper {
      border: solid 1px #515860;
      background-color: #2f363d;
      border-radius: 18px;
      display: flex;
      padding: 0 5px;
      margin-right: 10px;
    }

    .coupons-content {
      text-align: left;
    }

    .coupon {
      margin: 0 5px 0 10px;
      color: #fff;
      font-weight: 800;
    }

    img {
      width: 23px;
      transform: translateY(-4%);
    }
  }
}
</style>
