<script setup>
import { nextTick, onMounted, onUnmounted } from 'vue'

let gameInstance = null
const containerId = 'game-container'
const game = await import(/* webpackChunkName: "game" */ '@/game/game')

onMounted(() => {
  nextTick(() => {
    gameInstance = game.launch(containerId)
    window.dispatchEvent(new Event('resize'))
  })
})

onUnmounted(() => {
  gameInstance.destroy(false)
})
</script>

<template>
  <div :id="containerId" />
</template>

<style lang="scss">
#game-container {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  canvas {
    // background: #1d6645;
  }
}
</style>
