<template>
  <TopBar />
  <transition
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
    mode="out-in"
  >
    <div v-if="viewState === 'emailConfirm'" class="email-icon-wrapper">
      <img src="@/assets/images/email.svg" />
    </div>
    <div v-else class="phone-icon-wrapper">
      <img src="@/assets/images/phone.svg" />
    </div>
  </transition>
  <div class="wrapper">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <div v-if="viewState === 'phoneOptin'" class="opt-in-wrapper">
        <h1>Hoe kunnen we je bereiken?</h1>
        <p>
          Vul het mobiele nummer in waarop je de uitslag wil ontvangen. Als je wint, gebruiken we
          dit nummer om je dat te laten weten.
        </p>
        <div class="form-group">
          <label class="form-label" for="phoneNumber">Mobiel nummer</label>
          <input
            v-model="phoneNumber"
            class="form-input"
            type="tel"
            id="phoneNumber"
            maxlength="13"
            placeholder="0612345678"
          />
        </div>
        <div class="button-wrapper">
          <button
            :disabled="!isValid || verificationIsLoading"
            @click="startVerification()"
            class="btn btn-primary"
          >
            <template v-if="verificationIsLoading">
              <span class="loading loading-lg"></span>
            </template>
            <template v-else>Bevestigen</template>
          </button>
        </div>
      </div>
      <div v-else-if="viewState === 'verification'" class="opt-in-wrapper">
        <img
          class="arrow-back"
          @click="viewState = 'phoneOptin'"
          src="@/assets/images/arrow-left-circle.svg"
        />

        <h1>Bevestig je mobiele nummer</h1>
        <p>Vul de verificatiecode in die je hebt ontvangen op het ingegeven mobiele nummer.</p>

        <div class="form-group" :class="{ 'animate__animated animate__shakeX': verificationError }">
          <label class="form-label" for="verificationCode">Verificatiecode</label>
          <input
            v-model="verificationCode"
            class="form-input"
            type="tel"
            id="verificationCode"
            maxlength="6"
            placeholder="123456"
          />
        </div>

        <div class="button-wrapper">
          <button
            :disabled="!isValid || verificationIsLoading"
            @click="verifyOptin()"
            class="btn btn-primary"
          >
            <template v-if="verificationIsLoading">
              <span class="loading loading-lg"></span>
            </template>
            <template v-else>Bevestigen</template>
          </button>
        </div>
        <div class="retry-link-wrapper">
          Geen sms ontvangen?
          <a @click="startVerification()" class="terms-link">Probeer het opnieuw</a>
        </div>
      </div>
      <div v-else-if="viewState === 'emailConfirm'" class="opt-in-wrapper">
        <h1>Bevestig je e-mailadres</h1>
        <p>
          Controleer of dit het e-mailadres is waar je je prijs op wilt ontvangen als je wint.
        </p>
        <div class="form-group">
          <label class="form-label" for="phoneNumber">E-mailadres</label>
          <input
            v-model="email"
            class="form-input"
            type="email"
            id="email"
            maxlength="64"
            autocomplete="false"
            placeholder="hi@vodafoneziggo.nl"
          />
        </div>
        <div class="button-wrapper">
          <button
            :disabled="!emailIsValid"
            @click="confirmEmail()"
            class="btn btn-primary"
          >
            <template v-if="verificationIsLoading">
              <span class="loading loading-lg"></span>
            </template>
            <template v-else>Bevestigen</template>
          </button>
        </div>
      </div>
    </transition>
    <div class="terms-link-wrapper">
      <a class="terms-link" :href="$store.terms" target="_blank">Actievoorwaarden</a>
    </div>
  </div>
</template>

<script setup>
import TopBar from '@/components/TopBar.vue'
</script>

<script>
export default {
  data() {
    return {
      viewState: 'phoneOptin',
      verificationIsLoading: false,
      optinCheck: false,
      verificationCode: '',
      verificationError: false
    }
  },
  created() {},
  mounted() {},
  computed: {
    phoneNumber: {
      get() {
        return this.$store.userInput.phoneNumber
      },
      set(value) {
        this.$store.userInput.phoneNumber = value
      }
    },
    email: {
      get() {
        return this.$store.userInput.email
      },
      set(value) {
        this.$store.userInput.email = value
      }
    },
    emailIsValid() {
      return String(this.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    isValid() {
      return this.phoneNumber.length >= 10
    }
  },
  methods: {
    confirmEmail(){
      this.verificationIsLoading = true

      this.$store
        .verifyOptin('email', this.email)
        .then((response) => {
          this.verificationIsLoading = false
          this.$router.push({ name: 'end' })
        })
        .catch((response) => {
          this.verificationError = true
          this.verificationIsLoading = false
          setTimeout(() => {
            this.verificationError = false
          }, 500)
          console.log(response.response.data.error)
        })
    },
    startVerification() {
      this.verificationIsLoading = true
      this.$store.startVerification().then((response) => {
        this.viewState = 'verification'
        this.verificationIsLoading = false
      })
    },
    verifyOptin() {
      this.verificationIsLoading = true
      this.$store
        .verifyOptin('code', this.verificationCode)
        .then((response) => {
          this.verificationIsLoading = false
          this.viewState = 'emailConfirm';
        })
        .catch((response) => {
          this.verificationError = true
          this.verificationIsLoading = false
          setTimeout(() => {
            this.verificationError = false
          }, 500)
          console.log(response.response.data.error)
        })
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 215px);
}
.email-icon-wrapper, .phone-icon-wrapper {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 30px;
}

.opt-in-wrapper {
  padding: 0 40px;

  .arrow-back {
    position: absolute;
    top: -60px;
  }

  .key-visual {
    width: 100%;
  }

  .form-input:focus{
    background: none!important
  }

  .form-checkbox {
    margin-top: 20px;
  }

  .button-wrapper {
    margin-top: 20px;

    .btn {
      display: block;
    }
  }

  .retry-link-wrapper {
    margin: 40px 0;
    color: rgba(#fff, 0.6);
    //text-align: center;
  }
}
.terms-link-wrapper {
  margin-top: auto;
  padding-top: 40px;
  text-align: center;
}
</style>
