<template>
  <transition
    enter-active-class="animate__animated animate__faster animate__fadeIn"
    leave-active-class="animate__animated animate__faster animate__fadeOut"
  >
    <GameTopBar v-if="$store.gameStarted || $store.gameFinished" />
  </transition>
  <GameBackground />
  <GameEventEffects />
  <transition
    enter-active-class="animate__animated animate__faster animate__fadeIn"
    leave-active-class="animate__animated animate__faster animate__fadeOut"
  >
    <CountdownOverlay v-if="!$store.gameStarted && !$store.gameFinished" />
  </transition>
  <Game />
</template>

<script setup>
import Game from '@/components/Game.vue'
import GameBackground from '@/components/Game/GameBackground.vue'
import GameEventEffects from '@/components/Game/GameEventEffects.vue'
import GameTopBar from '@/components/Game/GameTopBar.vue'
import CountdownOverlay from '@/components/Game/CountdownOverlay.vue'
</script>

<script>
export default {
  data() {
    return {}
  },
  created() {},
  mounted() {
    this.$store.dataLayerPush({
      event: 'play_game'
    })
  },
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style lang="scss" scoped></style>
