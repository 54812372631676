<template>
  <div class="countdown-holder">
    <Vue3Lottie
      class="lottieCountdown"
      :animationData="lottieCountdownJSON"
      :loop="false"
      @onComplete="startGame()"
    />
  </div>
</template>

<script>
import lottieCountdownJSON from '@/assets/lottie/countdown-data.json'

export default {
  data() {
    return {
      lottieCountdownJSON
    }
  },
  methods: {
    startGame() {
      this.$store.gameStarted = true
      this.$store.gameEventBus({ type: 'gameStarted' })
    }
  },
  computed: {}
}
</script>

<style scoped lang="scss">
.countdown-holder {
  height: 100vh;
  width: 100%;
  position: absolute;
  background: rgba(#000, 0.6);
  top: 0;
  left: 0;
  z-index: 10;
  img {
    position: absolute;
    height: 150%;
    left: 50%;
    top: 0;
    transform: translateX(-50%) translateY(-15%);
    z-index: -1;
  }
  .lottieCountdown {
    max-width: 150px;
    margin: 0 auto;
  }
}
</style>
