import { createRouter, createWebHashHistory, createMemoryHistory } from 'vue-router'
import LandingView from '@/views/LandingView.vue'
import InstructionsView from '@/views/InstructionsView.vue'
import GameView from '@/views/GameView.vue'
import ScoreView from '@/views/ScoreView.vue'
import ShopView from '@/views/ShopView.vue'
import OptInView from '@/views/OptInView.vue'
import EndView from '@/views/EndView.vue'

const history = import.meta.env.DEV
  ? createWebHashHistory(import.meta.env.BASE_URL)
  : createMemoryHistory()

const router = createRouter({
  history: history,
  routes: [
    {
      path: '/',
      name: 'landing',
      component: LandingView
    },
    {
      path: '/instructions',
      name: 'instructions',
      component: InstructionsView
    },
    {
      path: '/game',
      name: 'game',
      component: GameView
    },
    {
      path: '/score',
      name: 'score',
      component: ScoreView
    },
    {
      path: '/shop',
      name: 'shop',
      component: ShopView
    },
    {
      path: '/opt-in',
      name: 'opt-in',
      component: OptInView
    },
    {
      path: '/end',
      name: 'end',
      component: EndView
    }
  ]
})

router.beforeEach((to, from, next) => {
  window._ddm.trigger('routeChange.ready', {
    data: {
      page: {
        environment: import.meta.env.VITE_GTM_ENV, // Dev environment e.g. jit/test/acc/prod/
        web_view: true // Indicates if we are in the webview or on the open page
      }
    }
  })

  next()
})

export default router
