// import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { useMainStore } from '@/stores'

import App from './App.vue'
import router from './router'

import Vue3Lottie from 'vue3-lottie'
import * as Sentry from '@sentry/vue'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(Vue3Lottie)

const store = useMainStore()
app.config.globalProperties.$store = store

Sentry.init({
  app,
  dsn: 'https://d3894bb2b46ce408234e135e0f7d244a@o62542.ingest.us.sentry.io/4507707945451520',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

router.isReady().then(() => app.mount('#app'))

window.rInterval = function (callback, delay) {
  var dateNow = Date.now,
    requestAnimation = window.requestAnimationFrame,
    start = dateNow(),
    stop,
    intervalFunc = function () {
      dateNow() - start < delay || ((start += delay), callback())
      stop || requestAnimation(intervalFunc)
    }
  requestAnimation(intervalFunc)
  return {
    clear: function () {
      stop = 1
    }
  }
}
