<template>
  <div class="input-coupon" :class="{ 'is-enlarged': enlarged }">
    <button class="input-btn input-btn-minus" :disabled="disableMinus" @click="minus()">-</button>
    <span class="input">{{ number }}</span>
    <button class="input-btn input-btn-plus" :disabled="disablePlus" @click="plus()">+</button>
  </div>
</template>

<script>
export default {
  props: ['modelValue', 'available', 'limit', 'enlarged'],
  emits: ['update:modelValue'],
  data() {
    return {}
  },
  methods: {
    plus() {
      this.number += 1
    },
    minus() {
      this.number -= 1
    }
  },
  computed: {
    number: {
      get() {
        if (isNaN(this.modelValue)) {
          return 0
        }
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    disablePlus() {
      if (this.available === 0) {
        return true
      }
      if (this.number === this.limit) {
        return true
      }
      return false
    },
    disableMinus() {
      if (this.number <= 0) {
        return true
      }
      return false
    }
  },
  watch: {}
}
</script>

<style scoped lang="scss">
.input-coupon {
  display: flex;

  &.is-enlarged {
    .input-btn {
      width: 45px;
      height: 45px;
      font-size: 28px;
    }
    .input {
      font-size: 24px;
      width: 45px;
      height: 45px;
    }
  }
  .input {
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 32px;
    height: 32px;
    color: #101820;
    border-radius: 6px;
    border: solid 1px #dce3e9;
    margin: 0 5px;
  }
  .input-btn {
    font-size: 20px;
    padding: 0;
    line-height: 1.7;
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 50%;
    border: none;
    background-color: #7f2fb0;
    color: #fff;
    transition: all 0.3s ease;

    &:disabled {
      background-color: #6f7780;
      color: #2f363d;
    }
  }

  .input-btn-plus {
    line-height: 1.5;
  }
}
</style>
