<template>
  <div class="gamebackground-holder">
    <template v-for="(img, index) in images">
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <div
          class="background"
          v-if="index === activeBg"
          :style="{ backgroundImage: 'url(' + img + ')' }"
        ></div>
      </transition>
    </template>
  </div>
</template>

<script>
import bg1 from '@/assets/images/bg_festival.jpg'
import bg2 from '@/assets/images/bg_soccer.jpg'
import bg3 from '@/assets/images/bg_muziek.jpg'

export default {
  data() {
    return {
      activeBg: 0,
      images: [bg1, bg2, bg3]
    }
  },
  mounted() {
    // setInterval(() => {
    //   if (this.activeBg === this.images.length - 1) {
    //     this.activeBg = 0
    //   } else {
    //     this.activeBg += 1
    //   }
    // }, 3000)
  },
  methods: {},
  computed: {
    roundedScore() {
      return Math.floor(this.$store.gameData.score / 1000)
    },
    bgSrc() {
      return this.images[this.activeBg]
    }
  },
  watch: {
    roundedScore() {
      if (this.activeBg === this.images.length - 1) {
        this.activeBg = 0
      } else {
        this.activeBg += 1
      }
    }
  }
}
</script>

<style scoped lang="scss">
.gamebackground-holder {
  position: absolute;
  //opacity: 0.3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;

  .background {
    bottom: 0;
    position: absolute;
    user-select: none;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    z-index: 0;
  }

  img {
    position: absolute;
    user-select: none;
    width: 100%;
    bottom: 0;
  }
}
</style>
