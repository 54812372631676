<template>
  <TopBar />
  <div class="wrapper">
    <div class="content-wrapper">
      <div class="content">
        <h2>Jouw score</h2>
        <div class="score">{{ animation.score }}</div>
        <div class="coupons">
          <img src="@/assets/images/coupon.svg" />
          <span class="coupon">{{ animation.coupons }}</span>
          {{ couponPlural(animation.coupons) }} verdiend
        </div>
        <div v-if="previousPlayedSessions" class="coupons bonus">
          <img src="@/assets/images/coupon.svg" />
          <span class="coupon">+ {{ previousPlayedSessions }}</span
          >bonus
          {{ couponPlural(previousPlayedSessions) }}
        </div>
      </div>
      <div class="visual">
        <img :class="{ show: showKeyVisual }" src="@/assets/images/key-visual.png" />
      </div>
    </div>
    <div :class="{ show: showFooterContent }" class="footer-content">
      <template v-if="consolationPrize">
        <h1 class="text-center">Je ontvangt een lot!</h1>
        <p class="text-center">
          Het zit niet altijd mee, maar je krijgt toch een lot van ons als bedankje voor het
          meespelen<template v-if="previousPlayedSessions"
            >én je ontvangt {{ previousPlayedSessions }} bonus
            {{ couponPlural(previousPlayedSessions) }}!</template
          >
          Je mag ze nu gaan inzetten op jouw favoriete prijzenpotten.
        </p>
      </template>
      <template v-else>
        <h1 class="text-center">Goed gedaan!</h1>
        <p class="text-center">
          Met jouw score heb je {{ coupons }} {{ couponPlural(coupons) }} verdiend<template
            v-if="previousPlayedSessions"
            >, én je ontvangt {{ previousPlayedSessions }} bonus
            {{ couponPlural(previousPlayedSessions) }}!</template
          >
          <template v-else>.</template> Je mag ze nu gaan inzetten op jouw favoriete prijzenpotten.
        </p>
      </template>
      <button
        :disabled="!showFooterContent"
        @click="$router.push({ name: 'shop' })"
        class="btn btn-primary"
      >
        Zet je <template v-if="coupons === 1">lot</template><template v-else>loten</template> in
      </button>
    </div>
  </div>
</template>

<script setup>
import TopBar from '@/components/TopBar.vue'
</script>

<script>
export default {
  data() {
    return {
      animation: {
        score: 0,
        coupons: 0
      },
      showKeyVisual: false,
      showFooterContent: false
    }
  },
  created() {},
  mounted() {
    this.animateScore(0, this.score)
    setTimeout(() => {
      this.showKeyVisual = true
    }, 800)
  },
  computed: {
    score() {
      return this.$store.gameData.score
    },
    coupons() {
      return this.$store.gameData.coupons
    },
    previousPlayedSessions() {
      return this.$store.player.previousPlayedSessions
    },
    consolationPrize() {
      if (this.score < 1000) {
        return true
      }
      return false
    }
  },
  methods: {
    animateScore(start, end) {
      const duration = 3000
      let startTimestamp = null
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp
        const progress = Math.min((timestamp - startTimestamp) / duration, 1)
        this.animation.score = Math.floor(progress * (end - start) + start)
        let calculatedCoupons = Math.floor(this.animation.score / 1000)
        if (calculatedCoupons <= this.$store.maxCoupons) {
          this.animation.coupons = calculatedCoupons
        }
        if (progress < 1) {
          window.requestAnimationFrame(step)
        } else {
          this.showFooterContent = true
          if (this.score < 1000) {
            this.animation.coupons = this.coupons
          }
        }
      }
      window.requestAnimationFrame(step)
    },
    couponPlural(amount) {
      if (amount > 1 || amount === 0) {
        return 'loten'
      }
      return 'lot'
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  margin-top: 30px;

  .content {
    width: 100%;
    padding-left: 25px;
    padding-top: 20px;
    z-index: 10;

    h2 {
      font-size: 27px;
      margin-bottom: 0;
    }
    .score {
      font-size: 87px;
      font-weight: 800;
      line-height: 1;
    }
    .coupons {
      display: inline-flex;
      padding: 5px 15px;
      background: #2f363d;
      color: #a1a4a8;
      border: solid 1px #515860;
      border-radius: 50px;
      font-size: 21px;
      line-height: 2;

      &.bonus {
        margin-top: 5px;
        color: #101820;
        font-weight: 800;
        background-color: #fff;

        .coupon {
          color: #101820;
          font-weight: 800;
        }
      }

      .coupon {
        margin: 0 5px 0 10px;
        color: #fff;
        font-weight: 800;
      }

      img {
        transform: translateY(-4%);
      }
    }
  }
  .visual {
    position: absolute;
    right: 0;
    width: 50%;
    z-index: 0;

    img {
      opacity: 0;
      width: 180%;
      margin-top: -40px;
      transform: translateX(10%);
      transition: all 0.6s ease-out;

      &.show {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}
.footer-content {
  opacity: 0;
  margin-top: 30px;

  padding: 20px;
  transform: translateY(10%);
  transition: all 0.6s ease-out;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
