import { defineStore } from 'pinia'
import axios from 'axios'
import { Howl, Howler } from 'howler'
import router from '@/router/index.js'

import music from '@/assets/sounds/priority_mixdown.mp3'
import coffeeCatch from '@/assets/sounds/coffee_catch.mp3'
import frozen from '@/assets/sounds/frozen_game.mp3'
import regularCatch from '@/assets/sounds/regular_catch.mp3'
import ticketEarned from '@/assets/sounds/ticket_earned.mp3'

import prizeImgMoviesSeries from '@/assets/images/prizes/movies-series.png'
import prizeImgWinnen from '@/assets/images/prizes/winnen.png'
import prizeImgSport2 from '@/assets/images/prizes/sport2.png'
import prizeImgMuziek from '@/assets/images/prizes/muziek.png'
import prizeImgMusical from '@/assets/images/prizes/musical.png'
import prizeImgSport from '@/assets/images/prizes/sport.png'

import prizeImgMoviesSeriesFull from '@/assets/images/prizes/movies-series-full.png'
import prizeImgWinnenFull from '@/assets/images/prizes/winnen-full.png'
import prizeImgSport2Full from '@/assets/images/prizes/sport2-full.png'
import prizeImgMuziekFull from '@/assets/images/prizes/muziek-full.png'
import prizeImgMusicalFull from '@/assets/images/prizes/musical-full.png'
import prizeImgSportFull from '@/assets/images/prizes/sport-full.png'

export const useMainStore = defineStore('main', {
  state: () => ({
    endpoints: {
      api: import.meta.env.VITE_API_ENDPOINT
    },
    player: {},
    isAuthenticated: false,
    uuidKey: 'XsLoIICuaIHe',
    gameLoaded: false,
    gameStarted: false,
    gameFinished: false,
    gameDuration: 25,
    revealRound: false,
    maxCoupons: 5,
    soundIsMuted: false,
    soundPlayer: {},
    sounds: {
      music: music,
      coffeeCatch: coffeeCatch,
      frozen: frozen,
      regularCatch: regularCatch,
      ticketEarned: ticketEarned
    },
    gameData: {
      score: 0,
      coupons: 0,
      timer: 0,
      isFrozen: false,
      isSpeedup: false,
      eventBus: []
    },
    userInput: {
      couponDistribution: {},
      phoneNumber: '',
      email: ''
    },
    prizeCategories: [
      {
        id: 'musical',
        title: 'Musical',
        subTitle: '2 Premium tickets voor Disney FROZEN',
        categoryUrl: 'https://www.priority.nl/categorie/musical',
        img: prizeImgMusical,
        imgFull: prizeImgMusicalFull,
        prizes: [
          {
            title: 'Tickets voor Disney FROZEN',
            description:
              'Win 2 Premium tickets voor de musical Disney FROZEN! Frozen, gebaseerd op de gelijknamige animatiefilm uit 2013, is in Nederlandse vertaling te zien in het AFAS Circustheater Scheveningen. '
          }
        ]
      },
      // {
      //   id: 'movies_series',
      //   title: 'Movies & series',
      //   subTitle: '2 Pathé Premium Film vouchers',
      //   categoryUrl: 'https://www.priority.nl/categorie/movies_en_series',
      //   img: prizeImgMoviesSeries,
      //   imgFull: prizeImgMoviesSeriesFull,
      //   prizes: [
      //     {
      //       title: '2 Pathé Premium Film vouchers',
      //       description:
      //         '2 Kaarten voor een Pathé Premium film naar keuze (inclusief 3D/4D), 2 jaar geldig.'
      //     }
      //   ]
      // },
      // {
      //   id: 'deals',
      //   title: 'Deals',
      //   subTitle: 'Sony ULT Wear black',
      //   categoryUrl: '',
      //   img: prizeImgWinnen,
      //   imgFull: prizeImgWinnenFull,
      //   prizes: [
      //     {
      //       title: '2x Sony ULT Wear black',
      //       description: 'Stijlvolle draadloze over-ear-koptelefoon.'
      //     }
      //   ]
      // },
      // {
      //   id: 'sport_2',
      //   title: 'Sport II',
      //   subTitle: 'Tickets Race Café-ervaring',
      //   categoryUrl: 'https://www.priority.nl/categorie/sport',
      //   img: prizeImgSport2,
      //   imgFull: prizeImgSport2Full,
      //   prizes: [
      //     {
      //       title: 'Een unieke Race Café-ervaring voor twee personen',
      //       description:
      //         'Wil je een live uitzending van het Ziggo Sport Race Café bijwonen? Win tickets voor een uitzending in november in de nieuwe Ziggo Sport studio in Hilversum, inclusief een rondleiding door het Mediapark.'
      //     }
      //   ]
      // },
      {
        id: 'muziek',
        title: 'Muziek',
        subTitle: '2 VIP tickets Ziggo Dome<br />',
        categoryUrl: 'https://www.priority.nl/categorie/muziek',
        img: prizeImgMuziek,
        imgFull: prizeImgMuziekFull,
        prizes: [
          {
            title: '2 VIP tickets Ziggo Dome',
            description:
              'Selecteer een top 3 concerten op basis van beschikbaarheid. Te verzilveren binnen 12 maanden na ontvangst.'
          }
        ]
      },
      {
        id: 'sport',
        title: 'Sport',
        subTitle: '2 VIP Tickets Ajax<br />5x Een Ajax accessoire <br />',
        categoryUrl: 'https://www.priority.nl/categorie/sport',
        img: prizeImgSport,
        imgFull: prizeImgSportFull,
        prizes: [
          {
            title: '2 Ajax VIP tickets',
            description:
              '2 Ajax VIP tickets, inclusief arrangement, parkeerplek en stoelen op de hoofdtribune bij een thuiswedstrijd.'
          },
          // {
          //   title: '2 Ajax thuiswedstrijd tickets',
          //   description: '2 Ajax tickets. Selecteer een top 5 thuis-wedstrijden in de Eredivisie.'
          // },
          {
            title: '3x Ajax wintertrui',
            description: 'Unieke Ajax wintertrui.'
          }
        ]
      }
    ],
    terms: 'Priority Spel Actie Voorwaardes.pdf'
  }),
  getters: {},
  actions: {
    authenticate(payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(this.endpoints.api + 'a', payload)
          .then((response) => {
            this.player = response.data
            localStorage.setItem(this.uuidKey, response.data.uuid)
            this.isAuthenticated = true
            if (response.data.couponDistribution) {
              this.userInput.couponDistribution = response.data.couponDistribution
            }
            if (response.data.email) {
              this.userInput.email = response.data.email
            }
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    saveGameSession() {
      const payload = {
        u: this.player.uuid,
        s: this.gameData.score,
        c: this.gameData.coupons,
        d: this.gameData.eventBus
      }
      axios.post(this.endpoints.api + 's-g', payload).catch(function (error) {
        console.log(error)
      })
    },
    submitCoupons() {
      const payload = {
        u: this.player.uuid,
        c: this.userInput.couponDistribution
      }
      axios.post(this.endpoints.api + 's-c', payload).catch(function (error) {
        console.log(error)
      })

      this.dataLayerPush({
        event: 'loten_inzetten',
        available_ticket: this.gameData.coupons,
        tickets_used: this.gameData.coupons,
        tickets_used_on: Object.keys(this.userInput.couponDistribution)
          .map((key) => key + ':' + this.userInput.couponDistribution[key])
          .join(',')
      })
    },
    startVerification() {
      const payload = {
        u: this.player.uuid,
        m: this.userInput.phoneNumber
      }

      this.dataLayerPush({
        event: 'sms_optin'
      })

      return new Promise((resolve, reject) => {
        axios
          .post(this.endpoints.api + 's-o', payload)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    },
    verifyOptin(type = 'code', value) {
      const payload = {
        u: this.player.uuid
      }

      if (type === 'code') {
        payload.c = value
      }

      if (type === 'email') {
        payload.e = value
      }

      return new Promise((resolve, reject) => {
        axios
          .post(this.endpoints.api + 'v-o', payload)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    },
    loadSounds() {
      for (const key in this.sounds) {
        let volume = 1

        let payload = {
          key: key,
          howl: new Howl({
            src: [this.sounds[key]],
            volume: volume,
            preload: true,
            loop: false,
            html5: true
          })
        }
        this.soundPlayer[payload.key] = payload.howl
      }
    },
    setMute(muteState) {
      this.soundIsMuted = muteState
      Howler.mute(muteState)
    },
    addPoints(points) {
      this.gameData.score += points
    },
    checkCoupons() {
      let calculatedCoupons = Math.floor(this.gameData.score / 1000)
      if (this.gameData.coupons < this.maxCoupons && this.gameData.coupons < calculatedCoupons) {
        this.gameData.coupons += 1
        this.gameEventBus({ type: 'coupon' })
      }
    },
    gameEventBus(payload) {
      payload._timestamp = Date.now()
      this.gameData.eventBus.push(payload)

      switch (payload.type) {
        case 'addPoints':
          this.addPoints(payload.points)
          this.soundPlayer['regularCatch'].play()
          this.checkCoupons()
          break
        case 'freeze':
          this.gameData.isFrozen = true
          this.soundPlayer['frozen'].play()
          break
        case 'freeze_end':
          this.gameData.isFrozen = false
          break
        case 'speedup':
          this.gameData.isSpeedup = true
          this.soundPlayer['coffeeCatch'].play()
          break
        case 'speedup_end':
          this.gameData.isSpeedup = false
          break
        case 'coupon':
          this.soundPlayer['ticketEarned'].play()
          break
        case 'gameStarted':
          this.soundPlayer['music'].play()
          break
        case 'gameFinshed':
          if (this.gameData.score < 1000) {
            this.gameData.coupons = 1
          }
          this.gameFinished = true
          this.gameStarted = false
          this.dataLayerPush({
            event: 'finish_game',
            score: this.gameData.score,
            tickets_earned: this.gameData.coupons, // nr of ticket earned
            time_played: this.gameDuration // time in seconds
          })
          this.saveGameSession()
          break
      }
    },
    dataLayerPush(payload) {
      payload = {
        ...payload,
        ...{ page_path: router.currentRoute.value.fullPath }
      }
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(payload)
    }
  }
})
