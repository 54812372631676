<template>
  <Snow />
  <RouterView v-if="isAuthenticated" />
  <div v-if="!isAuthenticated && !authError" class="loading loading-lg"></div>
  <div v-if="authError" class="auth-error">
    <h1 class="text-center">Er ging iets mis, je wordt terug gestuurd.</h1>
    <p class="auth-error-message">{{ authErrorMessage }}</p>
  </div>
</template>

<script setup>
import Snow from './components/Snow.vue'
</script>

<script>
import 'animate.css'

export default {
  data() {
    return {
      urlParams: new URLSearchParams(window.location.search),
      authError: false,
      authErrorMessage: ''
    }
  },
  created() {
    this.$store.loadSounds()
  },
  mounted() {
    const orderKey = this.urlParams.get('orderKey')

    if (orderKey) {
      this.authenticate({ token: orderKey })
    } else {
      this.authErrorRedirect('no orderKey')
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.isAuthenticated
    }
  },
  methods: {
    authenticate(payload) {
      this.$store
        .authenticate(payload)
        .then((response) => {
          const routeName = this.checkRouting()
          if (routeName) {
            // this.$router.push({ name: routeName })
          }
        })
        .catch((response) => {
          console.log(response.response.data)
          if (payload.token && response.response.data === 'session expired') {
            const uuid = localStorage.getItem(this.$store.uuidKey)
            if (uuid) {
              this.authenticate({ uuid: uuid })
            } else {
              this.authErrorRedirect('no fallback uuid available')
            }
          } else {
            this.authErrorRedirect(response.response.data)
          }
        })
    },
    authErrorRedirect(message) {
      this.authError = true
      this.authErrorMessage = message
      setTimeout(() => {
        history.back()
      }, 3000)
    },
    checkRouting() {
      if (this.$store.player.roundComplete && !this.$store.player.optin.msisdn) {
        return 'opt-in'
      }
      if (this.$store.player.roundComplete) {
        return 'end'
      }
      if (
        this.$store.player.gameSessions &&
        this.$store.player.gameSessions[this.$store.player.round]
      ) {
        this.$store.gameData.score = this.$store.player.gameSessions[this.$store.player.round].score
        this.$store.gameData.coupons =
          this.$store.player.gameSessions[this.$store.player.round].coupons
        return 'score'
      }
      if (this.$store.player.gameSessions === false) {
        return 'instructions'
      }
      if (this.$store.player.round === 1) {
        return 'instructions'
      }
      return 'landing'
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.auth-error {
  padding: 120px 20px;
}

.auth-error-message {
  text-align: center;
  opacity: 0.4;
}
</style>

<style lang="scss">
@font-face {
  font-family: 'Averta';
  src: url('@/assets/fonts/AvertaStd-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Averta';
  src: url('@/assets/fonts/AvertaStd-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Averta';
  src: url('@/assets/fonts/AvertaStd-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import url(https://campaigns.mobilewater.nl/cdn/css/spectre@0.5.9/spectre.min.css);

:root {
  --animate-duration: 600ms !important;
}

body {
  font-family: 'Averta', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #12181f;

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    overflow-x: hidden;
    touch-action: pan-y;
  }
}

.wrapper {
  position: relative;
  height: calc(100% - 60px); //topbar height
  max-width: 450px;
  margin: 0 auto;
}

h1 {
  font-size: 28px;
  font-weight: 800;
}

h2 {
  font-weight: 800;
  font-size: 18px;
}

.btn {
  position: relative;
  &.btn-primary {
    display: block;
    width: 100%;
    height: 55px;
    margin: 0 auto;
    line-height: 1;
    font-weight: bold;
    color: #fff;
    background-color: #7f2fb0;
    border-color: #7f2fb0;
    border-radius: 10px;
    box-shadow: 0 4px 0 0 #4d2280;

    &:hover {
      background-color: #661c94;
      border-color: #661c94;
    }
  }

  &.btn-outline-sm {
    color: #fff;
    line-height: 0;
    font-size: 14px;
    border-color: #fff;
    background: none;
    border-radius: 6px;
    padding: 5px 8px 2px 8px;
  }

  &.btn-outline {
    display: block;
    width: 100%;
    height: 55px;
    margin: 0 auto;
    line-height: 1;
    font-weight: bold;
    color: #fff;
    font-size: 14px;
    border-color: #fff;
    background: none;
    border-radius: 6px;
    //padding: 5px 8px 2px 8px;
  }

  &:disabled {
    opacity: 1;
    color: rgba(#fff, 0.8);
    background-color: #66696d;
    border-color: #66696d;
    box-shadow: none;
  }

  .loading {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    margin-top: 7px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.text-center {
  text-align: center;
}

a.terms-link {
  color: #ffffff;
  opacity: 0.6;
  text-decoration: underline;
}

.priority-card {
  position: relative;
  background: #2f363d;
  display: flex;
  width: 100%;
  max-width: 335px;
  margin: 20px auto 20px auto;
  border-radius: 20px;
  padding: 10px;
  z-index: 10;

  &.darker {
    background: #101820;
  }

  &.with-checked,
  &.with-cross {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-30%, -30%);
      background: url('@/assets/images/checked.svg');
      background-size: contain;
      width: 30px;
      height: 30px;
    }
  }

  &.with-cross:before {
    background: url(@/assets/images/unchecked.svg);
    background-size: contain;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  img {
    margin-right: 10px;
    max-width: 80px;
  }

  h2 {
    margin-bottom: 0;
  }

  p {
    text-align: left;
    margin-bottom: 0;
  }
}

.form-group {
  label {
    font-weight: 800;
  }
  input {
    background-color: #242b33;
    border: solid 1px #40464d;
    color: #fff;
    border-radius: 6px;
    padding: 20px 10px;
  }

  .form-checkbox {
    padding-left: 2rem;
    font-weight: normal;
    line-height: 1.2;

    input:checked + .form-icon {
      background-color: #fff;

      &:before {
        border-color: #000;
        height: 18px;
        width: 7px;
        margin-top: -11px;
      }
    }
    .form-icon {
      width: 30px;
      height: 30px;
      border-radius: 4px;
    }
  }
}

.overlay {
  background: rgba(#000, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 300;
  backdrop-filter: blur(6px);
}

.info-window {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  background: #2f363d;
  width: 100%;
  max-height: 90%;
  max-width: 450px;
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  z-index: 600;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 4rem;

  @media (min-width: 450px) {
    margin-left: calc(50% - 225px);
  }
  .trophy-wrapper {
    text-align: center;
    margin-top: -30%;
    img {
      max-width: 290px;
      width: 100%;
    }
  }
  .content-holder {
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    overflow-y: auto;

    footer {
      display: flex;
      margin-top: 30px;
      align-items: center;
      justify-content: space-between;
    }
  }

  .close {
    position: absolute;
    width: 26px;
    height: 26px;
    right: 20px;
    top: 20px;
  }
}

.loading {
  margin-top: 20%;

  &:after {
    border: 0.2rem solid #fff;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
  }
}
</style>
