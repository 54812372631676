<template>
  <div class="top-bar" :class="{ 'with-coupons': withCoupons }">
    <div class="inner-wrapper">
      <img src="@/assets/images/logo.svg" />
      <div v-if="withCoupons" class="coupons">
        <img src="@/assets/images/coupon.svg" />
        {{ coupons }}x
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['coupons'],
  data() {
    return {}
  },
  methods: {},
  computed: {
    withCoupons() {
      return this.coupons >= 0
    }
  }
}
</script>

<style scoped lang="scss">
.top-bar {
  height: 60px;
  width: 100%;
  background: #1b2128;
  border-bottom: solid 1px #39414a;
  display: flex;
  justify-content: center;
  align-items: center;

  &.with-coupons {
    position: fixed;
    z-index: 50;
    top: 0;
    .inner-wrapper {
      justify-content: space-between;
    }
  }

  .inner-wrapper {
    display: flex;
    width: 100%;
    line-height: 0;
    max-width: 335px;
    justify-content: center;

    .coupons {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 8px;
      background: #2f363d;
      border: solid 1px #515860;
      border-radius: 50px;
      font-size: 16px;

      img {
        margin-right: 5px;
        height: 18px;
      }
    }
  }
}
</style>
