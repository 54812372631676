<template>
  <div class="game-top-bar">
    <div class="left">
      <div class="timer">{{ timer }}</div>
    </div>
    <div class="centered">
      <div class="score">{{ score }}</div>
      <div class="coupons-holder">
        <template v-for="coupon in maxCoupons" :key="coupon">
          <transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
          >
            <span :class="{ filled: coupon <= coupons }" class="coupon" />
          </transition>
        </template>
      </div>
    </div>
    <div class="right">
      <div class="mute-holder">
        <span v-if="soundIsMuted" @click="unMuteSound()" class="mute"></span>
        <span v-else @click="muteSound()" class="unmute"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    muteSound() {
      this.$store.setMute(true)
    },
    unMuteSound() {
      this.$store.setMute(false)
    }
  },
  computed: {
    score() {
      return parseInt(this.$store.gameData.score, 10).toLocaleString('nl-NL')
    },

    coupons() {
      return this.$store.gameData.coupons
    },

    maxCoupons() {
      return this.$store.maxCoupons
    },

    timer() {
      // const minutes = Math.floor(this.$store.gameData.timer / 60).toLocaleString('nl-NL', {
      //   minimumIntegerDigits: 2
      // })
      const seconds = (this.$store.gameDuration - (this.$store.gameData.timer % 60)).toLocaleString(
        'nl-NL',
        {
          minimumIntegerDigits: 2
        }
      )
      return `00:${seconds}`
    },

    bgSrc() {
      return this.images[this.activeBg]
    },
    soundIsMuted() {
      return this.$store.soundIsMuted
    }
  },

  watch: {}
}
</script>

<style scoped lang="scss">
.game-top-bar {
  display: flex;
  color: #fff;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;
  top: 10px;
  user-select: none;
  z-index: 50;

  .left,
  .right {
    width: 80px;
  }

  .right {
    position: relative;
  }

  .timer {
    font-size: 18px;
    margin-top: 15px;
    padding-left: 10px;
    font-weight: 800;
  }

  .score {
    font-size: 44px;
    color: #fff;
    font-weight: 800;
    text-align: center;
    display: block;
  }
  .coupons-holder {
    height: 35px;
    z-index: 0;

    .coupon {
      display: inline-block;
      position: relative;
      height: 25px;
      width: 35px;

      &.filled {
        &::before {
          opacity: 0;
        }
        &::after {
          opacity: 1;
        }
      }

      &:after,
      &:before {
        position: absolute;
        opacity: 1;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background-image: url(@/assets/images/coupon_empty.svg);
        background-repeat: no-repeat;
        background-size: contain;
        transition: opacity 0.3s;
      }

      &::after {
        opacity: 0;
        background-image: url(@/assets/images/coupon_filled.svg);
      }
    }
  }

  .mute-holder {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 35px;
    height: 35px;
    z-index: 80;
    .unmute,
    .mute {
      position: absolute;
      background: url(@/assets/images/sound-off.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 35px;
      height: 35px;
      // right: 20px;
    }

    .unmute {
      background-image: url(@/assets/images/sound-on.svg);
    }
  }
}
</style>
